import { LINKS } from 'routes/constants'
import { Link } from 'routes/components/Shared'
import { IntegrationsVisual } from './IntegrationsVisual'
import clsx from 'clsx'
import classes from './LandingIntegrations.module.scss'

export const LandingIntegrations = () => {
  return (
    <section className={clsx('page__section', classes.section)}>
      <div className={clsx('flex-column', classes.heading)}>
        <h2 className={clsx('page__title', classes.title)}>
          Scale integration scenarios to thousands of apps with AI
        </h2>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          When you need to integrate with many apps in a similar way, our
          Universal Integrations framework saves you months of engineering and
          support time.
        </p>
      </div>

      <IntegrationsVisual bgIsVisible={true} />

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={LINKS.UNIVERSAL_INTEGRATIONS}
        >
          More about Universal Integrations
        </Link>
      </div>
    </section>
  )
}
