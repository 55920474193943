import AwsLogoImage from './logo-aws.png'
import CertificateLogoImage from './logo-certificate.png'
import DockerLogoImage from './logo-docker.png'
import KubernetesLogoImage from './logo-kubernetes.png'
import clsx from 'clsx'
import classes from './Certification.module.scss'

export const InfrastructureCertification = ({
  className,
}: {
  className?: string
}) => {
  return (
    <div className={clsx(classes.wrapper, className)}>
      <ul className={classes.list}>
        <li className={clsx(classes.item, classes.item__certificate)}>
          <div className={classes.card}>
            <h3 className={classes.card_title}>
              Certified world-class security
            </h3>

            <p className={classes.card_description}>
              We are SOC-2 Type 2 certified, GDPR-compliant, and can address any
              of your or your customer's security concerns.
            </p>

            <ul className={classes.card_list}>
              <li className={classes.card_item}>
                <img
                  src={CertificateLogoImage.src}
                  alt='certificate logo'
                  loading={'lazy'}
                />
              </li>

              <li className={classes.card_item}>
                <p>SOC-2 certified</p>
                <p>GDPR compliant</p>
              </li>
            </ul>
          </div>
        </li>

        <li className={clsx(classes.item, classes.item__deployment)}>
          <div className={classes.card}>
            <h3 className={classes.card_title}>On-premise Deployment</h3>

            <p className={classes.card_description}>
              You can run our engine using Docker on your own infrastructure.
            </p>

            <ul className={classes.card_list}>
              <li className={classes.card_item}>
                <img src={AwsLogoImage.src} alt='aws logo' loading={'lazy'} />
              </li>

              <li className={classes.card_item}>
                <img
                  src={DockerLogoImage.src}
                  alt='docker logo'
                  loading={'lazy'}
                />
              </li>

              <li className={classes.card_item}>
                <img
                  src={KubernetesLogoImage.src}
                  alt='kubernetes logo'
                  loading={'lazy'}
                />
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  )
}
