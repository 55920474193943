import { BadgesG2 } from 'routes/components/Features'
import clsx from 'clsx'
import classes from './LandingBadges.module.scss'

export const LandingBadges = () => {
  return (
    <section className={clsx('flex-column', classes.section)}>
      <BadgesG2 />
    </section>
  )
}
