import { Reviews } from 'routes/components/Features'
import clsx from 'clsx'
import classes from './Reviews.module.scss'

export const BestSupportReviews = ({ className }: { className?: string }) => {
  return (
    <div className={clsx('flex-column', classes.wrapper, className)}>
      <h2 className={clsx('page__title', classes.title)}>
        Don't just take our word for it
      </h2>
      <p className={clsx('page__subtitle', classes.subtitle)}>
        Hear from some of our amazing customers
      </p>
      <Reviews />
    </div>
  )
}
