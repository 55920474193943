// import { Link } from 'routes/components/Shared'
import VendorLockSmImage from './landing-vendor-lock@1x.png'
import VendorLockMdImage from './landing-vendor-lock@2x.png'
import VendorLockLgImage from './landing-vendor-lock@3x.png'
import clsx from 'clsx'
import classes from './VendorLock.module.scss'

export const InfrastructureVendorLock = ({
  className,
}: {
  className?: string
}) => {
  return (
    <div className={clsx('flex-column', classes.wrapper, className)}>
      <div className={classes.content}>
        <div>
          <h2 className={clsx('page__title', classes.title)}>
            No vendor lock-in
          </h2>
          <div className={clsx('flex-column', classes.description)}>
            <p className={clsx('page__subtitle', classes.subtitle)}>
              Everything you build with us is highly transparent and portable.
              You have full access to your integration logic, connectors,
              customer configurations and credentials.
            </p>
            <p className={clsx('page__subtitle', classes.subtitle)}>
              If you decide to move part or all of your integrations elsewhere -
              you can do it.
            </p>
          </div>
        </div>
        {/* <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href='#'
        >
          Learn More
        </Link> */}
      </div>
      <div className={classes.visual}>
        <div className={clsx('page__glow', classes.glow)} />
        <picture className={classes.image}>
          <source media='(min-width: 768px)' srcSet={VendorLockLgImage.src} />
          <source
            media='(min-width: 576px) and (max-width: 767px)'
            srcSet={VendorLockMdImage.src}
          />
          <source media='(max-width: 575px)' srcSet={VendorLockSmImage.src} />
          <img
            src={VendorLockSmImage.src}
            alt='No vendor lock-in'
            loading='lazy'
          />
        </picture>
      </div>
    </div>
  )
}
