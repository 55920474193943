import { BestSupportBadges } from './Badges/Badges'
import { BestSupportReviews } from './Reviews/Reviews'
import clsx from 'clsx'
import classes from './LandingBestSupport.module.scss'

export const LandingBestSupport = () => {
  return (
    <section className={clsx('page__section', classes.section)}>
      <div className={classes.wrapper}>
        <BestSupportBadges className={classes.badges} />
        <BestSupportReviews />
      </div>
    </section>
  )
}
