import { TbArrowUpRight } from 'react-icons/tb'
import { Link } from 'routes/components/Shared'
import { CompaniesDataType } from './companiesData'
import clsx from 'clsx'
import classes from './LandingCompanies.module.scss'

export const LandingCompaniesCard = ({ card }: { card: CompaniesDataType }) => {
  const { topic, href, description, logo, status } = card

  return (
    <div className={clsx(classes.card, status && classes.card__active)}>
      <div className={clsx('flex-column', classes.card_body)}>
        <div className={classes.card_header}>
          <p className={classes.card_topic}>{topic}</p>

          <Link className={classes.card_link} href={href}>
            {status && <TbArrowUpRight className={classes.card_icon} />}
          </Link>
        </div>

        <div className={classes.card_logo}>
          <img src={logo.src} alt='case logo' />
        </div>

        <p className={classes.card_description}>{description}</p>
      </div>
    </div>
  )
}
